<template>
  <div class="article-page">
    <div class="article-head-wrapper">
      <div class="article-page__header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <div class="article-page__container" v-if="articleData && articleData[currentIndex]">
        <div class="article-page__container__author" v-html="articleData[currentIndex].field_authored_by"></div>
        <div class="article-page__container__title" v-html="articleData[currentIndex].title"></div>
        <div v-html="decoded_body" class="article-page__container__html-import"></div>
        <div class="article-page__container__news-heading">More News</div>
      </div>
      <div class="article-page__news-card-container" v-if="recommendation && recommendation.DATA">
        <div
          v-for="items in recommendation.DATA"
          :key="items.field_connect_id"
          @click="goToArticle(items.field_news_id)"
          class="more-news"
        >
          <NewsCard
            class="child"
            :type="items.field_article === 'true' ? 'ARTICLE' : 'COVERAGE'"
            :title="items.title"
            :imgUrl="'http://dev365.gdpventure.com' + items.field_news_slide_image"
          />
        </div>
      </div>
      <div v-if="articleData && articleData[currentIndex]" class="share-bar">
        <ShareBar
          :title="articleData[currentIndex].title"
          :showSusbscribeBar="showSusbscribeBar"
          :closeSubscribeBar="closeSubscribeBar"
          :newsID="pageUrl"
        />
        <ScrollIndicator />
      </div>
      <div class="subscribe-bar">
        <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
      </div>
    </div>
    <div class="article-page__footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import { Header, Footer, ShareBar, SubscribeBar, ScrollIndicator, NewsCard } from '@/components'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Article',
  data() {
    return {
      body: {
        count: 3,
        index: ''
      },
      scrollpx: 0,
      baseUrl: BACKEND_BASE_IMG_PATH,
      showSusbscribeBar: true,
      currentIndex: ''
    }
  },
  methods: {
    ...mapActions('article', ['getRecommendation']),
    ...mapActions('news', ['getArticle']),
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    goToArticle(newsID) {
      this.$router.push({ path: 'article', query: { newsID: newsID } })
    },
    getIndex() {
      for (let i = 0; i < this.articleData.length; i++) {
        if (this.articleData[i].field_news_id === this.currentNewsId) {
          this.currentIndex = i
          this.body.index = this.currentNewsId
          break
        }
      }
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  },
  computed: {
    ...mapState('article', ['recommendation']),
    ...mapState('news', ['articleData']),
    decoded_body: function() {
      return this.articleData[this.currentIndex].field_popup_body.replaceAll('src="/', 'src="' + this.baseUrl + '/')
    },
    currentNewsId() {
      return this.$route.query.newsID
    }
  },
  watch: {
    $route() {
      this.getArticle()
    },
    articleData() {
      if (this.articleData.length > 0) {
        this.getIndex()
        this.getRecommendation(this.body)
      }
    }
  },
  mounted() {
    this.getArticle()
  },
  created() {
    this.pageUrl = window.location.href
  },
  components: {
    Header,
    Footer,
    ShareBar,
    SubscribeBar,
    ScrollIndicator,
    NewsCard
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.article-page {
  .article-head-wrapper {
    min-height: calc(78vh);
  }
  &__header {
    position: relative;
    z-index: 1;
    top: 0;
    width: 100%;
    background: $primary-white;
  }
  &__container {
    z-index: -3;
    margin: 30px 250px 0 250px;
    @include ipad-landscape {
      margin: 30px 80px 0 80px;
    }
    @include ipad-portrait {
      margin: 30px 51px 0 51px !important;
    }
    @include ipad-10-landscape {
      margin: 30px 150px 0 150px;
    }
    @include ipad-10-portrait {
      margin: 30px 50px 0 50px !important;
    }
    @include iphone-portrait {
      margin: 30px 15px 0 15px;
    }
    @include ipad-default-portrait {
      margin: 30px 51px 0 51px !important;
    }
    @include ipad-default-landscape {
      margin: 30px 150px 0 151px !important;
    }
    @media (min-width: 1025px) and (max-width: 1279px) {
      margin-right: 80px;
      margin-left: 80px;
    }
    &__author {
      object-fit: contain;
      font-family: GothamBook;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: $primary-blue;
      padding-top: 10px;
      @include ipad-10-portrait {
        font-size: 18px !important;
        line-height: 1.67 !important;
        padding-top: 20px !important;
      }
      @include iphone-portrait {
        font-size: 16px;
        line-height: 1.38;
        padding-top: 10px;
      }
    }
    &__title {
      object-fit: contain;
      font-family: GothamMedium;
      font-size: 56px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: 0.9px;
      text-align: left;
      color: $heading-black;
      margin: 30px 0 30px 0;
      @include ipad-10-portrait {
        font-size: 56px !important;
        line-height: 1.18 !important;
        margin: 30px 0 30px 0 !important;
        font-weight: 500 !important;
      }
      @include iphone-portrait {
        font-family: GothamBook;
        font-size: 27px;
        line-height: 1.25;
        margin: 15px 0 15px 0;
        font-weight: normal;
      }
    }
    &__html-import {
      margin-bottom: 70px;
    }
    &__news-heading {
      object-fit: contain;
      font-family: GothamMedium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: $primary-black;
      margin-bottom: 30px;
      @include ipad-10-portrait {
        margin-bottom: 10px !important;
      }
      @include iphone-portrait {
        font-size: 16px;
        line-height: 1.38;
        margin-bottom: 20px;
      }
    }
  }
  &__news-card-container {
    margin: 0 250px 75px 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include ipad-portrait {
      flex-direction: row !important;
      column-gap: 15px;
      margin: 30px 51px 0 51px !important;
    }
    @include ipad-landscape {
      margin: 0 80px 75px 80px;
    }
    @include ipad-10-portrait {
      flex-direction: row !important;
      margin: 0 50px 50px 50px !important;
      justify-content: space-between;
    }
    @include iphone-portrait {
      flex-direction: column;
      margin: 0 15px 70px 15px;
    }
    @include ipad-10-landscape {
      margin: 30px 150px 0 150px;
    }
    @include ipad-default-portrait {
      margin: 0 51px 50px 51px !important;
    }
    @include ipad-default-landscape {
      margin: 30px 150px 50px 151px !important;
    }
    @media (min-width: 1025px) and (max-width: 1279px) {
      margin-right: 80px;
      margin-left: 80px;
    }
  }
  .share-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  .child {
    @include iphone-portrait {
      margin-bottom: 10px;
    }
    @include ipad-portrait {
      margin-bottom: 20px;
    }
  }
}
.more-news {
  @media (min-width: 897px) {
    width: 33%;
  }
}
::v-deep .news-card {
  @include ipad-default-portrait {
    max-width: 295px;
    max-height: 100%;
  }
  @include ipad-default-landscape {
    max-width: 340px;
  }
  @include ipad-landscape {
    max-width: 270px;
  }
  @include ipad-10-landscape {
    max-width: 255px;
  }
  @media (min-width: 897px) {
    max-width: 100%;
  }
}
.article-page__container__html-import ::v-deep a {
  word-break: break-all;
}
</style>
